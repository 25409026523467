.inputError.css-1pcexqc-container {
  border: 2px solid red;
  border-radius: 7px;
}

.errorBackground {
  background: #ffaeae;
}

.ant-table-tbody > tr.errorBackground.ant-table-row-level-0:hover > td {
  background: #ffaeae;
}
