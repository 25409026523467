.ant-input-number.cleanInputNumber {
  border: none;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}
.cleanInputNumber.ant-input-number-disabled {
  background-color: white;
}

.cleanInputNumber.ant-input-number-input:focus {
  border-radius: 0;
  border: none transparent;
  box-shadow: none;
}
.cleanInputNumber.ant-input-number-input:focus {
  border-radius: 0;
}
.cleanInputNumber.ant-input-number:focus-within {
  border: none transparent;
  box-shadow: none;
}
.cleanInputNumber.ant-input-number:focus {
  border: none transparent;
  box-shadow: none;
}
.cleanInputNumber.ant-input-number:hover {
  border: none transparent;
  box-shadow: none;
}
.cleanInputNumber.ant-input-number:active {
  border: none transparent;
  box-shadow: none;
}

.cleanInputNumber.ant-input-number-input-wrap {
  border: 0;
}

.cleanSelect .ant-select-selection {
  border: none transparent;
}

.cleanSelect .ant-select-selection--single {
  border: none transparent;
}

.ant-table-header-column {
  width: 100%;
}

.ant-table-tbody > tr.noHighlight.ant-table-row-level-0:hover > td {
  background: none;
}

.ant-select-item-option-content {
  white-space: pre-wrap;
}
