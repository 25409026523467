@media screen {
  #toPrint {
    display: none;
  }
}
.ant-table-align-center {
  padding: 0;
}
@media screen {
  div#toPrint {
    visibility: visible;
    position: absolute;
    top: 0;
    z-index: 1000;
    width: 800px;
    background: white;
    margin: 0 auto;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  #toPrint * {
    visibility: visible;
    color: black;
  }
  #toPrint {
    visibility: visible;
    position: absolute;
    height: auto;
    left: 0;
    top: 0;
    width: 1000px;
    margin: 0 auto;
  }

  tr {
    page-break-inside: avoid;
  }

  td {
    page-break-inside: avoid;
  }

  th {
    page-break-inside: avoid;
  }

  .ant-table-tbody > tr.noHighlight.ant-table-row-level-0 > td {
    border-color: black;
  }

  .ant-table-small.ant-table-bordered {
    border-color: black;
  }

  .ant-table-small.ant-table-bordered .ant-table-content {
    border-color: black;
  }

  .ant-table-small {
    border-color: black;
  }

  .ant-table-body {
    border-color: black;
  }

  .ant-table-small
    > .ant-table-content
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-body
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-tbody
    > tr
    > td {
    border-color: black;
    padding: 0 0 0 8px;
  }
  * {
    color: black;
    overflow: hidden !important;
  }
}

.ant-table-tbody > tr.noHighlight.ant-table-row-level-0:hover > td {
  background: none;
}
