.logo {
  position: relative;
  height: 64px;
  padding-left: 24px;
  overflow: hidden;
  line-height: 64px;
  background: #002140;
  transition: all 0.3s;
}

.prodexCaption {
  display: inline-block;
  margin: 0 0 0 12px;
  color: white;
  font-weight: 600;
  font-size: 20px;
  font-family: Avenir, "Helvetica Neue", Arial, Helvetica, sans-serif;
  vertical-align: middle;
}

.myHeader {
  background: #fff;
  padding: 0;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
/*.outerHeader{*/
/*    padding: 0px;*/
/*    width: 100%;*/
/*    z-index: 2;*/
/*    height: 64px;*/
/*    padding: 0 50px;*/
/*    line-height: 64px;*/
/*    background: #001529;*/
/*    flex: 0 0 auto;*/

/*}*/

/*.innerHeader {*/

/*    position: relative;*/
/*    height: 64px;*/
/*    padding: 0;*/
/*    background: #fff;*/

/*}*/

.headerTrigger {
  height: 64px;
  padding: calc((64px - 20px) / 2) 24px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
}
.headerTrigger:hover {
  background: rgba(0, 0, 0, 0.025);
}

.userInfo {
  display: inline-block;
  height: 100%;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.3s;
}
.notification {
  height: 64px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
}
.userInfo:hover {
  background: rgba(0, 0, 0, 0.025);
}
