.invoiceBody table {
  border: 1px solid black;
}

.invoiceBody td {
  padding: 3px;
  border: 1px solid black;
  font-family: "proxima-nova";
}

.invoiceBody th {
  padding: 3px;
  border: 1px solid black;
  text-align: center;
  font-family: "proxima-nova";
}
