.mt-wagesummary {
  color: white;
  padding: 20px;
  font-size: 17px;
  background-color: #001529;
  width: 100%;
  height: 100%;
}

.mt-wagesummary .ReactTable {
  border: 1px solid white;
}

.mt-wagesummary .ReactTable .rt-noData {
  background-color: #001529;
  display: "none";
}

.mt-wagesummary .ReactTable .rt-thead.-header {
  -webkit-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 0px 0 white;
}
