#sizes {
  /*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
  /*border-collapse: collapse;*/
  width: 100%;
}

#sizes td {
  border: 1px solid #ddd;
  padding: 6px;
}

.sizeDiv {
  overflow-x: auto;
}

#sizes th {
  border: 1px solid #ddd;
  padding-bottom: 4px;
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: normal;
}

#sizes tr:nth-child(even) {
  background-color: #f2f2f2;
}

#sizes tr:hover {
  /*background-color: #ddd;*/
}

#sizes th {
  /*padding-top: 12px;*/
  /*padding-bottom: 12px;*/
  text-align: left;
  background-color: #fafafa;
  color: black;
}
